import React, { useState, useEffect } from 'react';
import "./cabanas.css";
import PhotoCollage from '../../components/collage/collage.js';
import { useSpring, animated } from "react-spring";

const CabinInfo = ({ cabana1, cabana2, cabana3, exterior }) => {
  // Definir un estado para almacenar el elemento seleccionado
  const [selectedCabin, setSelectedCabin] = useState('cabana1');
  const [isVisible, setVisible] = useState(false);

  const cabinProps = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0px)' : 'translateY(50px)',
    config: { duration: 1000 }
  });

  const imagesWithId = (images) =>{
    const elementosConId = [];
    for (let i = 0; i < images.length; i++) {
      elementosConId.push({
        id: i + 1,
        url: images[i],
        alt: "image"+i
      });
    }
    return elementosConId;
  }
  const cabana1Images = imagesWithId(cabana1.photos)
  const cabana2Images = imagesWithId(cabana2.photos)
  const cabana3Images = imagesWithId(cabana3.photos)
  const exteriorImages = imagesWithId(exterior.photos)

  // Definir un objeto con información sobre diferentes cabañas  
  const cabins = {
    cabana1: {
      name: cabana1.name,
      photos: cabana1Images,
      img: cabana1.img,
      description: cabana1.description,
    },
    cabana2: {
      name: cabana2.name,
      photos: cabana2Images,
      img: cabana2.img,
      description: cabana2.description,
    },
    cabana3: {
      name: cabana3.name,
      photos: cabana3Images,
      img: cabana3.img,
      description: cabana3.description,
    },
    exterior: {
      name: exterior.name,
      photos: exteriorImages,
      img: exterior.img,
      description: exterior.description,
    }
  };

  // Función que se llama cuando se presiona un botón
  const handleButtonClick = (event) => {
    const buttonValue = event.target.value;
    setSelectedCabin(buttonValue);
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('cabañas');
      if (element && element.getBoundingClientRect().top <= window.innerHeight * 0.75) {
        setVisible(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Renderizar el selector de elementos y la información de la cabaña seleccionada
  return (
    <div id="cabañas" className="slider-container-cabanas pt-5 cabanas">
      <animated.h2 style={cabinProps} className="textoCabañas text-start mb-5">Nuestras cabañas</animated.h2>
      <animated.div style={cabinProps} className="slider-cabanas">
        <button value="cabana1" className="mx-3 btn" onClick={handleButtonClick}>{cabins.cabana1.name}</button>
        <button value="cabana2" className="mx-3 btn" onClick={handleButtonClick}>{cabins.cabana2.name}</button>
        <button value="cabana3" className="mx-3 btn" onClick={handleButtonClick}>{cabins.cabana3.name}</button>
        <button value="exterior" className="mx-3 btn" onClick={handleButtonClick}>{cabins.exterior.name}</button>
      </animated.div>
      <animated.div style={cabinProps} className="cabin-info">
        <h3 className="textoCabañas2">{cabins[selectedCabin].name}</h3>
        <img src={cabins[selectedCabin].img} alt={cabins[selectedCabin].name} className="cabanasPhotos" style={{maxWidth: "80%", height: "auto"}} />
        <p className="px-2">{cabins[selectedCabin].description}</p>
        <PhotoCollage photos={cabins[selectedCabin].photos}/>
      </animated.div>
    </div>
  );
};

export default CabinInfo;