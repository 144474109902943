import React, { useEffect, useState } from "react";
import "./ubicacion.css"
import { useSpring, animated } from "react-spring";

const Ubicacion = ({textos}) => {
  const [isVisible, setVisible] = useState(false);

  const spring = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0px)' : 'translateY(50px)',
    config: { duration: 1000 }
  });

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('ubicacion');
      if (element && element.getBoundingClientRect().top <= window.innerHeight * 0.75) {
        setVisible(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='pt-5 ubicacion' id="ubicacion">
      <animated.h2 style={spring} className="text-start textoUbicacion" >Ubicación</animated.h2>
    <animated.div style={spring} className="pt-5 ubicacion d-flex">
      <div style={{ flex: '1' }} className="iframe-container">
        <iframe
          title="Mapa de ubicación de las cabañas Rocas Amarillas"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.2328513390817!2d-68.54990968517762!3d-34.57297426337833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9679735e63207681%3A0x42ccc31740ec9e8a!2sCaba%C3%B1as%20Pinot%20Noir!5e0!3m2!1ses-419!2sar!4v1678457146988!5m2!1ses-419!2sar"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div style={{ flex: '1' }} className="UbicacionDiv">
        <h2>Ubicación de Pinot Noir</h2>
        <p className="px-2">
        {textos.descripcion1}
        </p>
        <p className="px-2">
        {textos.descripcion2}
        </p>
        <h2>Villa 25 de Mayo</h2>
        <p className="px-2">
        {textos.villa}
        </p>
      </div>
    </animated.div>
    </div>
  );
};

export default Ubicacion;
