import React from 'react';
import './WhatsAppButton.css';
import icono from "../../assets/icons/whatsapp.svg"

function WhatsAppButton() {
    return (
      <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=5492604608798&text=Hola,%20estoy%20interesado%20en%20las%20cabañas.%20Me%20podrías%20dar%20más%20información%20porfavor?.">
        <div className="whatsapp-button">
          <img src={icono} alt="WhatsApp icon" width="24" height="24" />
        </div>
      </a>
    );
  }

export default WhatsAppButton;
