import React, { useState, useEffect } from "react";
import { doc, updateDoc, getDocs, where, deleteDoc, addDoc, query, collection } from "firebase/firestore";
import { db } from "../../config/firebaseConfig.js"
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent, { cardContentClasses } from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const ImgMediaCardM = () => {
  const [cards, setCards] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");

  function extraerUrlImagen(urlFlickr) {
    if(urlFlickr[0] == "["){
      const regex = /\[img\](.+?)\[\/img\]/;
      const match = urlFlickr.match(regex);
      if (match) {
        return match[1];
      } else {
        return '';
      }
    }else{
      return urlFlickr;
    }
  }

  const fetchPromotions = async () => {
    const q = query(collection(db, 'promociones'));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
    setCards(data);
  };

  const DeletePromo = async (title) => {
    const q = query(collection(db, 'promociones'), where("title", "==", title));
    const querySnapshot = await getDocs(q)
    let Id = "";
    querySnapshot.forEach((doc) => {
      Id = doc.id
    });
    await deleteDoc(doc(db, "promociones", Id));
    const newCards = cards.filter(card => card.title !== title);
    setCards(newCards);
  };

  const subir = async(title, description, img) =>{
    const docRef = await addDoc(collection(db, "promociones"), {
      title,
      description,
      img,
    });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const imagen = await extraerUrlImagen(img);
    await subir(title, description, imagen)
    setTitle("");
    setDescription("");
    setImg("");
    await fetchPromotions();
  };

  useEffect(() => {
    fetchPromotions();
  }, []);

  return (
    <div className='d-flex flex-row justify-content-center'>
      <div id="Cabañas1" className="d-flex row justify-content-center mt-5 col-6">
      {cards.map((card) => (
        <div className="card col-2" key={card.title} style={{width: '18rem'}}>
          <img src={card.img} className="card-img-top" alt="..."/>
          <div className="card-body">
            <h5 className="card-title">{card.title}</h5>
            <p className="card-text">{card.description}</p>
            <Button onClick={() => DeletePromo(card.title)} size="small">Eliminar</Button>
          </div>
        </div>
      ))}
      </div>
    <form onSubmit={handleSubmit} className="col-6">
      <div className="form-group">
        <label htmlFor="title">Título</label>
        <input
          type="text"
          className="form-control"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Descripción</label>
        <textarea
          className="form-control"
          id="description"
          rows="3"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>
      <div className="form-group">
        <label htmlFor="img">Imagen</label>
        <input
          type="text"
          className="form-control"
          id="img"
          value={img}
          onChange={(e) => setImg(e.target.value)}
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Agregar promoción
      </button>
    </form>
    </div>
  );
}

export default ImgMediaCardM;
