import SliderCards from "../../components/cardsSlider/cardsLider.js";
import React, { useEffect, useState } from "react";
import "./promociones.css"
import { useSpring, animated } from "react-spring";


const Promociones = ({cards}) => {
    const [isVisible, setVisible] = useState(false);

    const spring = useSpring({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0px)' : 'translateY(50px)',
        config: { duration: 1000 }
      });

      useEffect(() => {
        const handleScroll = () => {
          const element = document.getElementById('promociones');
          if (element && element.getBoundingClientRect().top <= window.innerHeight * 0.75) {
            setVisible(true);
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return(
      <div className="otroDiv">
          <animated.h2 style={spring} className="mb-3 textoPromociones text-start">Promociones y Novedades</animated.h2>
        <div id="promociones" className="pt-5 pb-5 mx-0 px-0 d-flex row promociones justify-content-center">
          <div className="promociones-container">
            <animated.div style={spring}>
            <SliderCards cards={cards} />
            </animated.div>
          </div>
        </div>
      </div>

    );
};

export default Promociones;