import React, { useState, useEffect } from "react";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../config/firebaseConfig.js"

const CabanasM = ({cabanas1, cabanas2, cabanas3, exterior}) =>{

    const [cabana1Local, setCabana1Local] = useState(cabanas1);
    const [cabana2Local, setCabana2Local] = useState(cabanas2);
    const [cabana3Local, setCabana3Local] = useState(cabanas3);
    const [cabana4Local, setCabana4Local] = useState(exterior);
    const [deletedIndex, setDeletedIndex] = useState(null);
    const [deletedIndex2, setDeletedIndex2] = useState(null);
    const [deletedIndex3, setDeletedIndex3] = useState(null);
    const [deletedIndex4, setDeletedIndex4] = useState(null);
    const [newImage, setNewImage] = useState();
    const [newImage2, setNewImage2] = useState();
    const [newImage3, setNewImage3] = useState();
    const [newImage4, setNewImage4] = useState();
    const [newImageUrl, setNewImageUrl] = useState();
    const [newImageUrl2, setNewImageUrl2] = useState();
    const [newImageUrl3, setNewImageUrl3] = useState();
    const [newImageUrl4, setNewImageUrl4] = useState();
    const [newDescription1, setNewDescription1] = useState("");
    const [newDescription2, setNewDescription2] = useState("");
    const [newDescription3, setNewDescription3] = useState("");
    const [newDescription4, setNewDescription4] = useState("");

    function extraerUrlImagen(urlFlickr) {
        if(urlFlickr[0] == "["){
          const regex = /\[img\](.+?)\[\/img\]/;
          const match = urlFlickr.match(regex);
          if (match) {
            return match[1];
          } else {
            return '';
          }
        }else{
          return urlFlickr;
        }
      }
      
      useEffect(() => {
        const docRef1 = doc(db, "cabaña1", "ZPV2iBJcmAKbbLL3Vn2I");
        const docRef2 = doc(db, 'cabaña2', '2kp6zfC22Bs8qIyqyKzw');
        const docRef3 = doc(db, 'cabaña3', 'OjHyzWNJNl1zWahTpapb');
        const docRef4 = doc(db, 'exterior', 'E9pbe08YqcyDLEu0ceqK');
      
        const unsubscribe1 = onSnapshot(docRef1, (doc) => {
          if (doc.exists()) {
            setCabana1Local(doc.data());
          }
        });
      
        const unsubscribe2 = onSnapshot(docRef2, (doc) => {
          if (doc.exists()) {
            setCabana2Local(doc.data());
          }
        });
      
        const unsubscribe3 = onSnapshot(docRef3, (doc) => {
          if (doc.exists()) {
            setCabana3Local(doc.data());
          }
        });
      
        const unsubscribe4 = onSnapshot(docRef4, (doc) => {
          if (doc.exists()) {
            setCabana4Local(doc.data());
          }
        });
      
        return () => {
          unsubscribe1();
          unsubscribe2();
          unsubscribe3();
          unsubscribe4();
        };
      }, []);

    const handleDeleteField1 = async (index) => {
        // Eliminar el campo correspondiente del array 'imagenes'
        const newCabana1Images = [...cabana1Local.photos];
        newCabana1Images.splice(index, 1);
        
        // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
        const docRef = doc(db, "cabaña1", "ZPV2iBJcmAKbbLL3Vn2I");
        const updateData = {
            photos: newCabana1Images,
        };
        await updateDoc(docRef, updateData);
    
        // Actualizar el estado local de 'aboutUs'
        const actualizar = cabana1Local;
        actualizar.photos = newCabana1Images
        setCabana1Local(actualizar);
    
        // Actualizar la variable de estado 'deletedIndex'
        setDeletedIndex(index);
    };
    
    const handleChangeImage = async (e) => {
        e.preventDefault();
        if (!newImage) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const newImg = extraerUrlImagen(newImage)
        const ImageRef = doc(db, 'cabaña1', 'ZPV2iBJcmAKbbLL3Vn2I');
        await updateDoc(ImageRef, {img: newImg});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        const actualizar = cabana1Local;
        actualizar.img = newImg
        setCabana1Local(actualizar);
        setNewImage("");
      };
      const handleChangeImage2 = async (e) => {
        e.preventDefault();
        if (!newImage2) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const newImg = extraerUrlImagen(newImage2)
        const ImageRef = doc(db, 'cabaña2', '2kp6zfC22Bs8qIyqyKzw');
        await updateDoc(ImageRef, {img: newImg});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        const actualizar = cabana2Local;
        actualizar.img = newImg
        setCabana2Local(actualizar);
        setNewImage2("");
      };
      const handleChangeImage3 = async (e) => {
        e.preventDefault();
        if (!newImage3) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const newImg = extraerUrlImagen(newImage3)
        const ImageRef = doc(db, 'cabaña3', 'OjHyzWNJNl1zWahTpapb');
        await updateDoc(ImageRef, {img: newImg});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        const actualizar = cabana3Local;
        actualizar.img = newImg
        setCabana3Local(actualizar);
        setNewImage3("");
      };
      const handleChangeImage4 = async (e) => {
        e.preventDefault();
        if (!newImage4) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const newImg = extraerUrlImagen(newImage4)
        const ImageRef = doc(db, 'exterior', 'E9pbe08YqcyDLEu0ceqK');
        await updateDoc(ImageRef, {img: newImg});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        const actualizar = cabana4Local;
        actualizar.img = newImg
        setCabana4Local(actualizar);
        setNewImage4("");
      };

    const handleAddImage1 = async (e) => {
        e.preventDefault();
        if (!newImageUrl) return;

        
        const newImg = extraerUrlImagen(newImageUrl)
        // Agregar la nueva imagen al final del array 'imagenes'
        const newCabanasPhotos = [...cabana1Local.photos, newImg];
        const ImageRef = doc(db, 'cabaña1', 'ZPV2iBJcmAKbbLL3Vn2I');
        await updateDoc(ImageRef, {photos: newCabanasPhotos});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        // Actualizar el estado local de 'aboutUs'
        const actualizar = cabana1Local;
        actualizar.photos = newCabanasPhotos
        setCabana1Local(actualizar);
    
        // Actualizar la variable de estado 'deletedIndex'
        setNewImageUrl("");
      };

      const handleDeleteField2 = async (index) => {
        // Eliminar el campo correspondiente del array 'imagenes'
        const newCabana2Images = [...cabana2Local.photos];
        newCabana2Images.splice(index, 1);
        
        // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
        const docRef = doc(db, "cabaña2", "2kp6zfC22Bs8qIyqyKzw");
        const updateData = {
            photos: newCabana2Images,
        };
        await updateDoc(docRef, updateData);
    
        // Actualizar el estado local de 'aboutUs'
        const actualizar = cabana2Local;
        actualizar.photos = newCabana2Images
        setCabana2Local(actualizar);
    
        // Actualizar la variable de estado 'deletedIndex'
        setDeletedIndex2(index);
    };

    const handleAddImage2 = async (e) => {
        e.preventDefault();
        if (!newImageUrl2) return;
      
        const newImg = extraerUrlImagen(newImageUrl2)
        // Agregar la nueva imagen al final del array 'imagenes'
        const newCabanasPhotos = [...cabana2Local.photos, newImg];
        const ImageRef = doc(db, 'cabaña2', '2kp6zfC22Bs8qIyqyKzw');
        await updateDoc(ImageRef, {photos: newCabanasPhotos});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        // Actualizar el estado local de 'aboutUs'
        const actualizar = cabana2Local;
        actualizar.photos = newCabanasPhotos
        setCabana2Local(actualizar);
    
        // Actualizar la variable de estado 'deletedIndex'
        setNewImageUrl2("");
      };

      const handleDeleteField3 = async (index) => {
        // Eliminar el campo correspondiente del array 'imagenes'
        const newCabana3Images = [...cabana3Local.photos];
        newCabana3Images.splice(index, 1);
        
        // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
        const docRef = doc(db, "cabaña3", "OjHyzWNJNl1zWahTpapb");
        const updateData = {
            photos: newCabana3Images,
        };
        await updateDoc(docRef, updateData);
    
        // Actualizar el estado local de 'aboutUs'
        const actualizar = cabana3Local;
        actualizar.photos = newCabana3Images
        setCabana3Local(actualizar);
    
        // Actualizar la variable de estado 'deletedIndex'
        setDeletedIndex3(index);
    };

    const handleAddImage3 = async (e) => {
        e.preventDefault();
        if (!newImageUrl3) return;
      
        const newImg = extraerUrlImagen(newImageUrl3)
        // Agregar la nueva imagen al final del array 'imagenes'
        const newCabanasPhotos = [...cabana3Local.photos, newImg];
        const ImageRef = doc(db, 'cabaña3', 'OjHyzWNJNl1zWahTpapb');
        await updateDoc(ImageRef, {photos: newCabanasPhotos});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        // Actualizar el estado local de 'aboutUs'
        const actualizar = cabana3Local;
        actualizar.photos = newCabanasPhotos
        setCabana3Local(actualizar);
    
        // Actualizar la variable de estado 'deletedIndex'
        setNewImageUrl3("");
      };

      const handleDeleteField4 = async (index) => {
        // Eliminar el campo correspondiente del array 'imagenes'
        const newCabana4Images = [...cabana4Local.photos];
        newCabana4Images.splice(index, 1);
        
        // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
        const docRef = doc(db, "exterior", "E9pbe08YqcyDLEu0ceqK");
        const updateData = {
            photos: newCabana4Images,
        };
        await updateDoc(docRef, updateData);
    
        // Actualizar el estado local de 'aboutUs'
        const actualizar = cabana4Local;
        actualizar.photos = newCabana4Images
        setCabana4Local(actualizar);
    
        // Actualizar la variable de estado 'deletedIndex'
        setDeletedIndex4(index);
    };

    const handleAddImage4 = async (e) => {
        e.preventDefault();
        if (!newImageUrl4) return;
      
        const newImg = extraerUrlImagen(newImageUrl4)
        // Agregar la nueva imagen al final del array 'imagenes'
        const newCabanasPhotos = [...cabana4Local.photos, newImg];
        const ImageRef = doc(db, 'exterior', 'E9pbe08YqcyDLEu0ceqK');
        await updateDoc(ImageRef, {photos: newCabanasPhotos});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        // Actualizar el estado local de 'aboutUs'
        const actualizar = cabana4Local;
        actualizar.photos = newCabanasPhotos
        setCabana4Local(actualizar);
    
        // Actualizar la variable de estado 'deletedIndex'
        setNewImageUrl4("");
      };

      const handleAddDescription1 = async (e) => {
        e.preventDefault();
        if (!newDescription1) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const newDescription = newDescription1;
      
        // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
        const AboutUsRef = doc(db, 'cabaña1', 'ZPV2iBJcmAKbbLL3Vn2I');
        await updateDoc(AboutUsRef, {description: newDescription});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        setNewDescription1(newDescription);
        setNewDescription1("");
      };

      const handleAddDescription2 = async (e) => {
        e.preventDefault();
        if (!newDescription2) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const newDescription = newDescription2;
      
        // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
        const AboutUsRef = doc(db, 'cabaña2', '2kp6zfC22Bs8qIyqyKzw');
        await updateDoc(AboutUsRef, {description: newDescription});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        setNewDescription2(newDescription);
        setNewDescription2("");
      };

      const handleAddDescription3 = async (e) => {
        e.preventDefault();
        if (!newDescription3) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const newDescription = newDescription3;
      
        // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
        const AboutUsRef = doc(db, 'cabaña3', 'OjHyzWNJNl1zWahTpapb');
        await updateDoc(AboutUsRef, {description: newDescription});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        setNewDescription3(newDescription);
        setNewDescription3("");
      };

      const handleAddDescription4 = async (e) => {
        e.preventDefault();
        if (!newDescription4) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const newDescription = newDescription4;
      
        // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
        const AboutUsRef = doc(db, 'exterior', 'E9pbe08YqcyDLEu0ceqK');
        await updateDoc(AboutUsRef, {description: newDescription});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        setNewDescription4(newDescription);
        setNewDescription4("");
      };

    return(
        <div>
            <div>
                <div id="Cabañas1" className="d-flex row justify-content-center mt-5">
                    <h3>{cabana1Local.name}</h3>
                    <div className="d-flex justify-content-center mb-5">
                        <div className="col-1"></div>
                        <img src={cabana1Local.img} className="col-3"/>
                        <form onSubmit={handleChangeImage} className="col">
                            <div className="mb-3 w-100">
                            <label htmlFor="new-image-url" className="form-label">Nueva Imagen</label>
                            <input
                                type="text"
                                className="form-control"
                                id="new-image-url"
                                value={newImage}
                                onChange={(e) => setNewImage(e.target.value)}
                            />
                            </div>
                            <button type="submit" className="btn btn-primary">
                            Cambiar imagen
                            </button>
                        </form>
                    </div>
                    <div>
                        <form onSubmit={handleAddDescription1}>
                            <div className="mb-3">
                            <label htmlFor="new-image-url" className="form-label">Descripcion</label>
                            <textarea
                                className="form-control"
                                id="new-image-url"
                                placeholder={cabana1Local.description}
                                value={newDescription1}
                                onChange={(e) => setNewDescription1(e.target.value)}
                                rows="3"
                            ></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">
                            Cambiar Parrafo
                            </button>
                        </form>
                    </div>
                    {cabana1Local.photos.map((element, index) => (
                        <div className="card col-2" style={{width: '18rem'}} key={index}>
                            <img src={element} className="card-img-top" alt="..."/>
                            <div className="card-body">
                            <button className="btn btn-danger" onClick={() => handleDeleteField1(index)}>Eliminar</button>
                            </div>
                        </div>
                    ))}
                </div>
                <form onSubmit={handleAddImage1}>
                    <div className="mb-3">
                        <label htmlFor="new-image-url" className="form-label">Nueva Imagen</label>
                        <input
                        type="text"
                        className="form-control"
                        id="new-image-url"
                        value={newImageUrl}
                        onChange={(e) => setNewImageUrl(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Agregar imagen
                    </button>
                </form>
                <div id="Cabañas2" className="d-flex row justify-content-center mt-4">
                <h3>{cabana2Local.name}</h3>
                <div className="d-flex justify-content-center mb-5">
                        <div className="col-1"></div>
                        <img src={cabana2Local.img} className="col-3"/>
                        <form onSubmit={handleChangeImage2} className="col">
                            <div className="mb-3 w-100">
                            <label htmlFor="new-image-url" className="form-label">Nueva Imagen</label>
                            <input
                                type="text"
                                className="form-control"
                                id="new-image-url"
                                value={newImage2}
                                onChange={(e) => setNewImage2(e.target.value)}
                            />
                            </div>
                            <button type="submit" className="btn btn-primary">
                            Cambiar imagen
                            </button>
                        </form>
                    </div>
                    <div>
                        <form onSubmit={handleAddDescription2}>
                            <div className="mb-3">
                            <label htmlFor="new-image-url" className="form-label">Descripcion</label>
                            <textarea
                                className="form-control"
                                id="new-image-url"
                                placeholder={cabana2Local.description}
                                value={newDescription2}
                                onChange={(e) => setNewDescription2(e.target.value)}
                                rows="3"
                            ></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">
                            Cambiar Parrafo
                            </button>
                        </form>
                    </div>
                    {cabana2Local.photos.map((element, index) => (
                        <div className="card col-2" style={{width: '18rem'}} key={index}>
                            <img src={element} className="card-img-top" alt="..."/>
                            <div className="card-body">
                            <button className="btn btn-danger" onClick={() => handleDeleteField2(index)}>Eliminar</button>
                            </div>
                        </div>
                    ))}
                </div>
                <form onSubmit={handleAddImage2}>
                    <div className="mb-3">
                        <label htmlFor="new-image-url" className="form-label">Nueva Imagen</label>
                        <input
                        type="text"
                        className="form-control"
                        id="new-image-url"
                        value={newImageUrl2}
                        onChange={(e) => setNewImageUrl2(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Agregar imagen
                    </button>
                </form>
                <div id="Cabañas3" className="d-flex row justify-content-center mt-4">
                <h3>{cabana3Local.name}</h3>
                <div className="d-flex justify-content-center mb-5">
                        <div className="col-1"></div>
                        <img src={cabana3Local.img} className="col-3"/>
                        <form onSubmit={handleChangeImage3} className="col">
                            <div className="mb-3 w-100">
                            <label htmlFor="new-image-url" className="form-label">Nueva Imagen</label>
                            <input
                                type="text"
                                className="form-control"
                                id="new-image-url"
                                value={newImage3}
                                onChange={(e) => setNewImage3(e.target.value)}
                            />
                            </div>
                            <button type="submit" className="btn btn-primary">
                            Cambiar imagen
                            </button>
                        </form>
                    </div>
                    <div>
                        <form onSubmit={handleAddDescription3}>
                            <div className="mb-3">
                            <label htmlFor="new-image-url" className="form-label">Descripcion</label>
                            <textarea
                                className="form-control"
                                id="new-image-url"
                                placeholder={cabana3Local.description}
                                value={newDescription3}
                                onChange={(e) => setNewDescription3(e.target.value)}
                                rows="3"
                            ></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">
                            Cambiar Parrafo
                            </button>
                        </form>
                    </div>
                    {cabana3Local.photos.map((element, index) => (
                        <div className="card col-2" style={{width: '18rem'}} key={index}>
                            <img src={element} className="card-img-top" alt="..."/>
                            <div className="card-body">
                            <button className="btn btn-danger" onClick={() => handleDeleteField3(index)}>Eliminar</button>
                            </div>
                        </div>
                    ))}
                </div>
                <form onSubmit={handleAddImage3}>
                    <div className="mb-3">
                        <label htmlFor="new-image-url" className="form-label">Nueva Imagen</label>
                        <input
                        type="text"
                        className="form-control"
                        id="new-image-url"
                        value={newImageUrl3}
                        onChange={(e) => setNewImageUrl3(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Agregar imagen
                    </button>
                </form>
                <div id="Cabañas4" className="d-flex row justify-content-center mt-4">
                <h3>{cabana4Local.name}</h3>
                <div className="d-flex justify-content-center mb-5">
                        <div className="col-1"></div>
                        <img src={cabana4Local.img} className="col-3"/>
                        <form onSubmit={handleChangeImage4} className="col">
                            <div className="mb-3 w-100">
                            <label htmlFor="new-image-url" className="form-label">Nueva Imagen</label>
                            <input
                                type="text"
                                className="form-control"
                                id="new-image-url"
                                value={newImage4}
                                onChange={(e) => setNewImage4(e.target.value)}
                            />
                            </div>
                            <button type="submit" className="btn btn-primary">
                            Cambiar imagen
                            </button>
                        </form>
                    </div>
                    <div>
                        <form onSubmit={handleAddDescription4}>
                            <div className="mb-3">
                            <label htmlFor="new-image-url" className="form-label">Descripcion</label>
                            <textarea
                                className="form-control"
                                id="new-image-url"
                                placeholder={cabana4Local.description}
                                value={newDescription4}
                                onChange={(e) => setNewDescription4(e.target.value)}
                                rows="3"
                            ></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">
                            Cambiar Parrafo
                            </button>
                        </form>
                    </div>
                    {cabana4Local.photos.map((element, index) => (
                        <div className="card col-2" style={{width: '18rem'}} key={index}>
                            <img src={element} className="card-img-top" alt="..."/>
                            <div className="card-body">
                            <button className="btn btn-danger" onClick={() => handleDeleteField4(index)}>Eliminar</button>
                            </div>
                        </div>
                    ))}
                </div>
                <form onSubmit={handleAddImage4}>
                    <div className="mb-3">
                        <label htmlFor="new-image-url" className="form-label">Nueva Imagen</label>
                        <input
                        type="text"
                        className="form-control"
                        id="new-image-url"
                        value={newImageUrl4}
                        onChange={(e) => setNewImageUrl4(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Agregar imagen
                    </button>
                </form>
            </div>
        </div>
    )
}

export default CabanasM;