import React, { useEffect, useState } from "react";
import "./servicios.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSpring, animated } from 'react-spring';



const ImageWithTitle = ({ imageUrl, title, description1, description2, services}) => {
    const [isVisible, setVisible] = useState(false);


    const spring = useSpring({ 
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0px)' : 'translateY(50px)',
        config: { duration: 1000 }
      });

    const ServiceList = ({ services }) => (
        <ul className="service-list">
        {services.map((service) => (
            <li className="col align-self-center" key={service}>{service}</li>
        ))}
        </ul>
    );

    useEffect(() => {
        const handleScroll = () => {
          const element = document.getElementById('servicios');
          if (element && element.getBoundingClientRect().top <= window.innerHeight * 0.75) {
            setVisible(true);
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return (
        <div id="servicios" className="servicios">
        <animated.div style={spring}>
        <h2 className="mb-4 textoServicios text-start">Servicios</h2>
        <div className="container d-flex justify-content-around">
            <animated.img className="image" src={imageUrl} alt="service" style={spring} />
            <div className="content px-3">
                <h2 className="title">{title}</h2>
                <p className="description">{description1}</p>
                <p className="description">{description2}</p>
                <div className="container flex-column justify-content-center">
                    <ServiceList services={services} />
                </div>
            </div>
        </div>
        </animated.div>
    </div>
    )
};

export default ImageWithTitle;
