import React, { useState, useEffect } from "react";
import "./SliderFrases.css";

const SliderFrases = ({ phrases }) => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const handlePrevClick = () => {
    setCurrentPhraseIndex(
      currentPhraseIndex === 0 ? phrases.length - 1 : currentPhraseIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentPhraseIndex(
      currentPhraseIndex === phrases.length - 1 ? 0 : currentPhraseIndex + 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isPaused) {
        setCurrentPhraseIndex(
          (currentPhraseIndex + 1) % phrases.length
        );
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [currentPhraseIndex, isPaused, phrases.length]);

  return (
    <div
      className="slider"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <button className="slider-button slider-button-prev" onClick={handlePrevClick}>
        <i className="fa fa-chevron-left"></i>
      </button>
      <button className="slider-button slider-button-next" onClick={handleNextClick}>
        <i className="fa fa-chevron-right"></i>
      </button>
      {phrases.map((phrase, index) => (
        <p
          key={index}
          className={`slider-phrase ${index === currentPhraseIndex ? "active" : ""}`}
        >
          {phrase}
        </p>
      ))}
    </div>
  );
};

export default SliderFrases;
