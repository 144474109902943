import React, { useState, useEffect } from "react";
import { doc, updateDoc, arrayUnion, arrayRemove, onSnapshot } from "firebase/firestore";
import { db } from "../../config/firebaseConfig.js"

const ServiciosM = ({servicios}) =>{
    const [newImageUrl, setNewImageUrl] = useState("");
    const [newServicios, setNewServicios] = useState(servicios);
    const [newDescription1, setNewDescription1] = useState("");
    const [newDescription2, setNewDescription2] = useState("");
    const [newService, setNewService] = useState("");

    useEffect(() => {
      const serviciosRef = doc(db, "servicios", "qcUdyfHGnD3qNHYjp9PF");
  
      // Escuchar cambios en la colección de servicios de Firestore en tiempo real
      const unsubscribe = onSnapshot(serviciosRef, (doc) => {
        if (doc.exists()) {
          setNewServicios(doc.data());
        }
      });
      return unsubscribe;
    }, []);

    function extraerUrlImagen(urlFlickr) {
      if(urlFlickr[0] == "["){
        const regex = /\[img\](.+?)\[\/img\]/;
        const match = urlFlickr.match(regex);
        if (match) {
          return match[1];
        } else {
          return '';
        }
      }else{
        return urlFlickr;
      }
    }

    const handleAddImage = async (e) => {
        e.preventDefault();
        if (!newImageUrl) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const url = extraerUrlImagen(newImageUrl)
        const ImageRef = doc(db, 'servicios', 'qcUdyfHGnD3qNHYjp9PF');
        await updateDoc(ImageRef, {imageURL: url});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        const actualizar = newServicios;
        actualizar.imageURL = url
        setNewServicios(actualizar);
        setNewImageUrl("");
      };

      const handleAddDescription1 = async (e) => {
        e.preventDefault();
        if (!newDescription1) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const newDescription = newDescription1;
      
        // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
        const AboutUsRef = doc(db, 'servicios', 'qcUdyfHGnD3qNHYjp9PF');
        await updateDoc(AboutUsRef, {description1: newDescription});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        setNewDescription1(newDescription);
        setNewDescription1("");
      };

      const handleAddDescription2 = async (e) => {
        e.preventDefault();
        if (!newDescription2) return;
      
        // Agregar la nueva imagen al final del array 'imagenes'
        const newDescription = newDescription2;
      
        // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
        const AboutUsRef = doc(db, 'servicios', 'qcUdyfHGnD3qNHYjp9PF');
        await updateDoc(AboutUsRef, {description2: newDescription});
      
        // Actualizar el estado local de 'aboutUs' y resetear el input
        setNewDescription2(newDescription);
        setNewDescription2("");
      };
      
      const removerServicio = async (name) => {
        const AboutUsRef = doc(db, "servicios", "qcUdyfHGnD3qNHYjp9PF");

        await updateDoc(AboutUsRef, {
          serviciosList: arrayRemove(name),
        });
      };
      
      const agregarServicio = async (e) => {
        e.preventDefault();
        if (!newService) return;
      
        const newServiceConst = newService;
      
        const AboutUsRef = doc(db, "servicios", "qcUdyfHGnD3qNHYjp9PF");
        await updateDoc(AboutUsRef, {
          serviciosList: arrayUnion(newServiceConst),
        });
      
        const updatedServicios = newServicios;
        updatedServicios.serviciosList.push(newServiceConst)
        setNewServicios(updatedServicios);
        setNewService("");
      };

return (
  <div className="row d-flex align-items-center justify-content-center">
    <div className="col-md-3">
      <form onSubmit={handleAddImage}>
        <div className="mb-3 w-100">
          <label htmlFor="new-image-url" className="form-label">Nueva Imagen</label>
          <input
            type="text"
            className="form-control"
            id="new-image-url"
            value={newImageUrl}
            onChange={(e) => setNewImageUrl(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Agregar imagen
        </button>
      </form>
    </div>
    <div className="col-md-5">
      <form onSubmit={handleAddDescription1}>
        <div className="mb-3">
          <label htmlFor="new-image-url" className="form-label">Nuevo parrafo 1</label>
          <textarea
            className="form-control"
            id="new-image-url"
            placeholder={newServicios.description1}
            value={newDescription1}
            onChange={(e) => setNewDescription1(e.target.value)}
            rows="3"
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          Cambiar Parrafo
        </button>
      </form>
      <form onSubmit={handleAddDescription2}>
        <div className="mb-3">
          <label htmlFor="new-image-url" className="form-label">Nuevo parrafo 2</label>
          <textarea
            className="form-control"
            id="new-image-url"
            placeholder={newServicios.description2}
            value={newDescription2}
            onChange={(e) => setNewDescription2(e.target.value)}
            rows="3"
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          Agregar Servicio
        </button>
      </form>
    </div>
    <div className="col-md-4 order-md-first" style={{ width: '20%' }}>
      <img src={newServicios.imageURL} className="card-img-top" alt="..." />
      <div className="card-body"></div>
    </div>
    <div>
      {newServicios.serviciosList.map((servicio)=>(
        <div className="d-flex justify-content-center align-items-center my-2" key={servicio}>
        <p className="mb-0 align-middle">{servicio}</p>
        <button className="mx-3 btn btn-danger" onClick={() => removerServicio(servicio)} >Quitar</button>
        </div>
      ))}
    </div>
    <div>
    <form onSubmit={agregarServicio}>
        <div className="mb-3">
          <label htmlFor="new-service-url" className="form-label">Nuevo Servicio</label>
          <input
            className="form-control"
            id="new-service-url"
            value={newService}
            onChange={(e) => setNewService(e.target.value)}
          ></input>
        </div>
        <button type="submit" className="btn btn-primary">
          Agregar Servicio
        </button>
      </form>
    </div>
  </div>
);
}

export default ServiciosM;