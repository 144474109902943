import React,{ useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../../assets/logos/CABANAS.png"
import "./NavBar.css"
import icono from "../../assets/icons/menu.png"
import facebook from "../../assets/icons/facebookBW.png"
import instagram from "../../assets/icons/instagramBW.png"

const NavbarComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav style={{maxWidth: "100%"}} id="navbar">
      <div className="logo">
        <a href="#home">
          <img
            src={logo}
            style={{ width: "auto", maxWidth: "100px" }}
            alt="Logo"
          />
        </a>
      </div>
      <div className={`links container container-fluid ${isOpen ? "open" : ""}`}>
        <a href="#quienes-somos" onClick={toggleMenu}>Quienes somos</a>
        <a href="#servicios" onClick={toggleMenu}>Servicios</a>
        <a href="#cabañas" onClick={toggleMenu}>Cabañas</a>
        <a href="#promociones" onClick={toggleMenu}>Promociones</a>
        <a href="#ubicacion" onClick={toggleMenu}>Ubicacion</a>
        <a href="#contacto" onClick={toggleMenu}>Contacto</a>
      </div>
      <div>
      <a href="https://www.instagram.com/cabanas_pinot_noir/" rel="noreferrer" target="_blank"><img src={instagram} alt="Instagram" /></a>
      <a href="https://www.facebook.com/search/top/?q=caba%C3%B1as%20pinot%20noir" rel="noreferrer" target="_blank"><img src={facebook} alt="Facebook" /></a>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        <img src={icono} alt="Icono" />
      </button>
    </nav>
  );
};

export default NavbarComponent;
