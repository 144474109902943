import React, { useEffect, useState } from "react";
import NavbarComponent from "../../components/NavBar/NavBar.js";
import AboutUs from "../sobreNosotros/sobreNosotros.js";
import ImageWithTitle from "../servicios/servicios.js";
import CabinInfo from "../cabanas/cabanas.js";
import Promociones from "../promociones/promociones.js";
import WhatsAppButton from "../../components/whatsButton/whatsButton.js";
import Ubicacion from "../ubicacion/ubicacion.js";
import ContactUs from "../formularioContacto/formularioContacto.js";
import Loader from "../../components/loader2/loader.js";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../config/firebaseConfig.js"
import imagen from "../../assets/images/4.png";
import imagen2 from "../../assets/images/2-dos-4.png";
import imagen3 from "../../assets/images/3-dos-2.png";
import imagen4 from "../../assets/images/4-dos-5.png";
import imagen5 from "../../assets/images/5-dos-3.png";
import imagen6 from "../../assets/images/6-dos-4.png";
import instagram from "../../assets/icons/instagram.png"
import facebook from "../../assets/icons/facebook.png"
import logo from "../../assets/logos/CABANAS.png"
import "./indexC.css"
import Admin from "../admin/admin.js";



const Index = () =>{

    const[servicios, setServicios] = useState()
    const [mostrarChau, setMostrarChau] = useState(false);
    const[aboutUs, setAboutUs] = useState()
    const[cabana1, setCabana1] = useState()
    const[cabana2, setCabana2] = useState()
    const[cabana3, setCabana3] = useState()
    const[exterior, setExterior] = useState()
    const[ubicacion, setUbicacion] = useState()
    const[promociones, setPromociones] = useState()
    const[loading, setLoading] = useState(true)

    const Footer = () => {
        return (
            <footer>
                <div className="container row justify-content-center">
                <div className="row">
                <button onClick={handleClick} className='linkAdmin' >switch</button>
                    <div className="col">
                    <a href='#navbar'><img src={logo} alt="Logo de Ejemplo" /></a>
                    </div>
                    <div className="col border-end border-2 row border-white align-items-center esteTambien">
                    <ul className="social-icons ml-auto">
                        <li><a href="https://www.facebook.com/search/top/?q=caba%C3%B1as%20pinot%20noir" rel="noreferrer" target="_blank"><img src={facebook} alt="Facebook" /></a></li>
                        <li><a href="https://www.instagram.com/cabanas_pinot_noir/" rel="noreferrer" target="_blank"><img src={instagram} alt="Instagram" /></a></li>
                    </ul>
                    </div>
                    <div className='col este'>
                    </div>
                    <div className='d-flex justify-content-center col mt-4'>
                    <div>
                        <div className="contact-info mx-3">
                        <h5>Contacto</h5>
                        <p>cabaniaspinotnoir@gmail.com</p>
                        </div>
                        <div className="contact-info mx-3">
                        <h5>Ubicación</h5>
                        <p>Calle Gobernador Garcia 1200, Villa 25 de Mayo, San Rafael</p>
                        </div>
                        <div className="contact-info mx-3">
                        <h5>Reserva</h5>
                        <p>2604608798 (Caro)</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </footer>
            );
        };
    
    const handleClick = () => {
        setMostrarChau(true);
        };
        
    const getServicios = async () => {
        const q = query(collection(db, 'servicios'));
        const docsServicios = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
                docsServicios.push({...doc.data(), id: doc.id})
        });
        setServicios(docsServicios[0]);
    };
    const getAbout = async () => {
        const q = query(collection(db, 'aboutUs'));
        const docsAbout = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
                docsAbout.push({...doc.data(), id: doc.id})
        });
        setAboutUs(docsAbout[0]);
    };
    const getCabana1 = async () => {
        const q = query(collection(db, 'cabaña1'));
        const docsCabana1 = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
                docsCabana1.push({...doc.data(), id: doc.id})
        });
        setCabana1(docsCabana1[0]);
    };
    const getCabana2 = async () => {
        const q = query(collection(db, 'cabaña2'));
        const docsCabana2 = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
                docsCabana2.push({...doc.data(), id: doc.id})
        });
        setCabana2(docsCabana2[0]);
    };
    const getCabana3 = async () => {
        const q = query(collection(db, 'cabaña3'));
        const docsCabana3 = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
                docsCabana3.push({...doc.data(), id: doc.id})
        });
        setCabana3(docsCabana3[0]);
    };
    const getExterior = async () => {
        const q = query(collection(db, 'exterior'));
        const docsExterior = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
                docsExterior.push({...doc.data(), id: doc.id})
        });
        setExterior(docsExterior[0]);
    };
    const getUbicacion = async () => {
        const q = query(collection(db, 'ubicacion'));
        const docsUbicacion = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
                docsUbicacion.push({...doc.data()})
        });
        setUbicacion(docsUbicacion[0]);
    };
    const getPromociones = async () => {
        const q = query(collection(db, 'promociones'));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        setPromociones(data);
        setLoading(false)
    };
    useEffect(() => {
            getServicios();
            getAbout();
            getCabana1();
            getCabana2();
            getCabana3();
            getExterior();
            getUbicacion();
            getPromociones()
    }, []);

    return(
        <div>
            {
                loading
                ?
                <div className="loader-container">
                    <div className="loader">
                        <Loader />
                    </div>
                </div>
                :
                <div>
                    {
                        mostrarChau
                        ?
                        <Admin mostrarChau={mostrarChau} setMostrarChau={setMostrarChau} />
                        :
                    <div className="elIndex">
                    <div style={{maxWidth: "100%"}}><NavbarComponent /></div>
                    <WhatsAppButton />
                    <AboutUs id="quienes-somos"
                    images={aboutUs.imagenes}
                    description1={aboutUs.description1}
                    description2={aboutUs.description2}
                    />
                    <img style={{maxWidth: "100%"}} src={imagen} alt="cabaña1"/>
                    <Promociones cards={promociones} />
                    <img style={{maxWidth: "100%"}} src={imagen2} alt="cabaña2"/>
                    <CabinInfo
                    cabana1={cabana1}
                    cabana2={cabana2}
                    cabana3={cabana3}
                    exterior={exterior}
                    />
                    <img style={{maxWidth: "100%"}} src={imagen3} alt="cabaña3" />
                    <ImageWithTitle
                    imageUrl={servicios.imageURL}
                    title={servicios.title}
                    description1={servicios.description1}
                    description2={servicios.description2}
                    services={servicios.serviciosList}
                    />
                    <img style={{maxWidth: "100%"}} src={imagen4} alt="cabaña4" />
                    <Ubicacion textos={ubicacion} />
                    <img style={{maxWidth: "100%"}} src={imagen5} alt="cabaña5" />
                    <ContactUs />
                    <img style={{maxWidth: "100%"}} src={imagen6} alt="cabaña6" />
                    <Footer />
                </div>
                    }
                </div>
            }
        </div>
    );
};

export default Index;