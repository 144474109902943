import React, { useEffect, useRef } from "react";
import "./AboutUs.css";
import SliderComponent from "../../components/Slider/slider.js";
import SliderFrases from "../../components/sliderFrases/sliderFrases";

const AboutUs = ({ images, description1, description2 }) => {
  const quienesSomosRef = useRef(null);

  useEffect(() => {
    const quienesSomos = quienesSomosRef.current;
    const slideUpClass = 'slide-up';
    const timeout = setTimeout(() => {
      quienesSomos.classList.add(slideUpClass);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const imagesWithId = (images) =>{
    const elementosConId = [];
    for (let i = 0; i < images.length; i++) {
      elementosConId.push({
        id: i + 1,
        url: images[i],
        alt: "image"+i
      });
    }
    return elementosConId;
  }
  const imagesArray = imagesWithId(images)

  const phrases = [
    "Hermoso lugar. Muy tranquilo volvería sin dudas. Muy recomendable  - Luciana Aguilar",
    "Exelente atencion. El Lugar es hermoso. Hay mucha paz -  Hernan Piro",
    "Un sitio unico por la tranquilidad. Muy buena atención de los dueños y la cabaña impecable -  Fernando Pachioli",
    "Hermoso todo..hermosa la cabaña..la limpieza.. la atención...super recomendable! -  Blanka Landini",
  ];

  return (
    <div>

      <div id="quienes-somos" className="sobre pt-4" ref={quienesSomosRef}>
      <h1 className="titulo mb-0 mb-2">Alojamiento Pinot Noir</h1>
        <SliderComponent imagesArray={imagesArray}/>
        <div className="about-us-container">
          <div className="about-us-text mx-5">
            <h2>¿Quiénes somos?</h2>
            <p className="about-us-text" >{description1}</p>
            <p className="about-us-text" >{description2}</p>
          </div>
          <div className="about-us-image"></div>
          <SliderFrases phrases={phrases} />
        </div>
      </div>
    </div>

  );
}

export default AboutUs;
