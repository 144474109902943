import React, { useState } from 'react';
import "./collage.css"


const PhotoCollage = ({ photos }) => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);

  const handlePhotoClick = (photo) => {
    const index = photos.findIndex((p) => p.id === photo.id);
    setSelectedPhotoIndex(index);
  };

  const handleModalClose = () => {
    setSelectedPhotoIndex(null);
  };

  const handlePreviousClick = () => {
    setSelectedPhotoIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setSelectedPhotoIndex((prevIndex) =>
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const selectedPhoto = selectedPhotoIndex !== null && photos[selectedPhotoIndex];

  return (
<div className="container d-flex row justify-content-center">
  <div className="photo-gallery">
    {photos.map((photo) => (
      <img
        key={photo.id}
        className="photo"
        src={photo.url}
        alt={photo.alt}
        onClick={() => handlePhotoClick(photo)}
      />
    ))}
  </div>

  {selectedPhoto && (
    <div className="modal">
      <img
        className="modal-photo"
        src={selectedPhoto.url}
        alt={selectedPhoto.alt}
        onClick={handleModalClose}
      />
      <button className="modal-close" onClick={handleModalClose}>
        &times;
      </button>
      <button className="modal-previous" onClick={handlePreviousClick}>
        &#8249;
      </button>
      <button className="modal-next" onClick={handleNextClick}>
        &#8250;
      </button>
    </div>
  )}
</div>
  );
};

export default PhotoCollage;
