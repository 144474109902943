import React, { useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import "./contacto.css"
import Swal from 'sweetalert2'
import { useSpring, animated } from "react-spring";

const ContactUs = () => {
  const form = useRef(null);
  const [isVisible, setVisible] = useState(false);

  const spring = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0px)' : 'translateY(50px)',
    config: { duration: 1000 }
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_wwm7dkn', 'template_cx07dh8', form.current, 'v58QiQ8cP636PFGdI')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      Swal.fire({
        icon: 'success',
        text: 'Mensaje enviado con exito!'
      })
    
      form.current.reset();
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('contacto');
      if (element && element.getBoundingClientRect().top <= window.innerHeight * 0.75) {
        setVisible(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    return (
        <div className="contacto pt-5" id='contacto'>
        <animated.h2 style={spring} className="text-start textoContacto">Contacto</animated.h2>
            <animated.form style={spring} ref={form} onSubmit={sendEmail}>
                <label>Nombre Completo</label>
                <input className='input' type="text" name="user_name" />
                <label>Telefono</label>
                <input className='input' type="text" name="user_tel" />
                <label>Email</label>
                <input className='input' type="email" name="user_email" />
                <label>Mensaje</label>
                <textarea className='input' name="message" />
                <input className='button' type="submit" value="Enviar" />
            </animated.form>
        </div>
    );
};

export default ContactUs;