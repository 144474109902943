import React, { useState, useEffect } from "react";
import Authentication from "../../components/auth/auth.js";
import { collection, query, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebaseConfig.js"
import ServiciosM from "../../components/serviciosM/serviciosM.js";
import CabanasM from "../../components/cabanasM/cabanasM.js";
import ImgMediaCardM from "../../components/promocionesM/promocionesM.js";
import UbicacionM from "../../components/ubicacionM/ubicacionM.js";
import "./admin.css";



const Admin = (props) => {
  const { mostrarChau, setMostrarChau } = props;
  const [isVerified, setIsVerified] = useState(false);
  const [servicios, setServicios] = useState();
  const [aboutUsImages, setAboutUsImages] = useState([]);
  const [aboutUsDescription1, setAboutUsDescription1] = useState([]);
  const [aboutUsDescription2, setAboutUsDescription2] = useState([]);
  const [newImageUrl, setNewImageUrl] = useState("");
  const [newDescription1, setNewDescription1] = useState("");
  const [newDescription2, setNewDescription2] = useState("");
  const [cabana1, setCabana1] = useState();
  const [cabana2, setCabana2] = useState();
  const [cabana3, setCabana3] = useState();
  const [exterior, setExterior] = useState();
  const [ubicacion, setUbicacion] = useState();

  function extraerUrlImagen(urlFlickr) {
    if(urlFlickr[0] == "["){
      const regex = /\[img\](.+?)\[\/img\]/;
      const match = urlFlickr.match(regex);
      if (match) {
        return match[1];
      } else {
        return '';
      }
    }else{
      return urlFlickr;
    }
  }

  const getAbout = async () => {
    const q = query(collection(db, 'aboutUs'));
    const docsAbout = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      docsAbout.push({...doc.data(), id: doc.id})
    });
    setAboutUsImages(docsAbout[0].imagenes);
    setAboutUsDescription1(docsAbout[0].description1);
    setAboutUsDescription2(docsAbout[0].description2);
  };
  const getServicios = async () => {
    const q = query(collection(db, 'servicios'));
    const docsServicios = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      docsServicios.push({...doc.data()})
    });
    setServicios(docsServicios[0]);
  };
  const getCabana1 = async () => {
    const q = query(collection(db, 'cabaña1'));
    const docsCabana1 = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      docsCabana1.push({...doc.data()})
    });
    setCabana1(docsCabana1[0]);
  };
  const getCabana2 = async () => {
    const q = query(collection(db, 'cabaña2'));
    const docsCabana2 = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      docsCabana2.push({...doc.data()})
    });
    setCabana2(docsCabana2[0]);
  };
  const getCabana3 = async () => {
    const q = query(collection(db, 'cabaña3'));
    const docsCabana3 = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      docsCabana3.push({...doc.data()})
    });
    setCabana3(docsCabana3[0]);
  };
  const getExterior = async () => {
    const q = query(collection(db, 'exterior'));
    const docsExterior = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      docsExterior.push({...doc.data()})
    });
    setExterior(docsExterior[0]);
  };
  const getUbicacion = async () => {
    const q = query(collection(db, 'ubicacion'));
    const docsUbicacion = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      docsUbicacion.push({...doc.data()})
    });
    setUbicacion(docsUbicacion[0]);
  };

  useEffect(() => {
    getAbout();
    getServicios();
    getCabana1();
    getCabana2();
    getCabana3();
    getExterior();
    getUbicacion();
  }, []);

  const handleVerificationSuccess = () => {
    setIsVerified(true);
  };

  // About Us

  const handleDeleteField = async (index) => {
    // Eliminar el campo correspondiente del array 'imagenes'
    const newAboutUsImages = [...aboutUsImages];
    newAboutUsImages.splice(index, 1);
    
    // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
    const docRef = doc(db, "aboutUs", "InzjDmD31Qtg9B93xDoG"); // reemplaza 'mi_doc_id' por el ID correcto
    const updateData = {
      imagenes: newAboutUsImages,
    };
    await updateDoc(docRef, updateData);

    // Actualizar el estado local de 'aboutUs'
    setAboutUsImages(newAboutUsImages);
  };

  const handleAddImage = async (e) => {
    e.preventDefault();
    if (!newImageUrl) return;

    const url = extraerUrlImagen(newImageUrl)
  
    // Agregar la nueva imagen al final del array 'imagenes'
    const newAboutUsImages = [...aboutUsImages, url];
    const ImageRef = doc(db, 'aboutUs', 'InzjDmD31Qtg9B93xDoG');
    await updateDoc(ImageRef, {imagenes: newAboutUsImages});
  
    // Actualizar el estado local de 'aboutUs' y resetear el input
    setAboutUsImages(newAboutUsImages);
    setNewImageUrl("");
  };

  const handleAddDescription1 = async (e) => {
    e.preventDefault();
    if (!newDescription1) return;
  
    // Agregar la nueva imagen al final del array 'imagenes'
    const newAboutUsDescription1 = newDescription1;
  
    // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
    const AboutUsRef = doc(db, 'aboutUs', 'InzjDmD31Qtg9B93xDoG');
    await updateDoc(AboutUsRef, {description1: newAboutUsDescription1});
  
    // Actualizar el estado local de 'aboutUs' y resetear el input
    setAboutUsDescription1(newAboutUsDescription1);
    setNewDescription1("");
  };

  const handleAddDescription2 = async (e) => {
    e.preventDefault();
    if (!newDescription2) return;
  
    // Agregar la nueva imagen al final del array 'imagenes'
    const newAboutUsDescription2 = newDescription2;
  
    // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
    const AboutUsRef = doc(db, 'aboutUs', 'InzjDmD31Qtg9B93xDoG');
    await updateDoc(AboutUsRef, {description2: newAboutUsDescription2});
  
    // Actualizar el estado local de 'aboutUs' y resetear el input
    setAboutUsDescription2(newAboutUsDescription2);
    setNewDescription2("");
  };

  if (!isVerified) {
    return <Authentication onVerificationSuccess={handleVerificationSuccess} />;
  }

  return (
    <div className="mt-5 cuerpo border-top border-2 border-white">
      <button onClick={() => setMostrarChau(false)} className="botonIndex">Index</button>
    <h2 className="seccion mb-0">Quiénes somos</h2>
      <div id="AboutUs" className="d-flex row justify-content-center seccion">
      <div className="d-flex flex-row justify-content-center col-8">
        {aboutUsImages.map((element, index) => (
          <div className="card" style={{width: '18rem'}} key={index}>
            <img src={element} className="card-img-top" alt="..."/>
            <div className="card-body">
              <button className="btn btn-danger" onClick={() => handleDeleteField(index)}>Eliminar</button>
            </div>
          </div>
        ))}
        </div>
        <form onSubmit={handleAddImage} className="col-3">
          <div className="mb-3">
            <label htmlFor="new-image-url" className="form-label">Nueva Imagen</label>
            <input
              type="text"
              className="form-control"
              id="new-image-url"
              value={newImageUrl}
              onChange={(e) => setNewImageUrl(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Agregar imagen
          </button>
        </form>
      </div>
      <div className="d-flex flex-row justify-content-center border-bottom border-2 border-white seccion">
      <form onSubmit={handleAddDescription1} className="col-6">
        <div className="mb-3">
          <label htmlFor="new-image-url" className="form-label">Nuevo párrafo 1</label>
          <textarea
            className="form-control"
            id="new-image-url"
            placeholder={aboutUsDescription1}
            value={newDescription1}
            onChange={(e) => setNewDescription1(e.target.value)}
            rows="3"
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          Agregar Párrafo
        </button>
      </form>
      <form onSubmit={handleAddDescription2} className="col-6">
        <div className="mb-3">
          <label htmlFor="new-image-url" className="form-label">Nuevo párrafo 2</label>
          <textarea
            className="form-control"
            id="new-image-url"
            value={newDescription2}
            onChange={(e) => setNewDescription2(e.target.value)}
            rows="3"
            placeholder={aboutUsDescription2}
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          Agregar Párrafo
        </button>
      </form>
      </div>
      <div className="mt-5 border-top border-bottom border-2 border-white seccion">
        <h2>Servicios</h2>
        <ServiciosM servicios={servicios}/>
      </div>
      <div className="mt-5 border-bottom border-top border-2 border-white seccion">
        <h2>Cabañas</h2>
        <CabanasM cabanas1={cabana1} cabanas2={cabana2} cabanas3={cabana3} exterior={exterior} />
      </div>
      <div className="mt-5 border-bottom border-top border-2 border-white seccion">
      <h2>Promociones y Novedades</h2>
        <ImgMediaCardM />
      </div>
      <div className="mt-5 border-bottom border-top border-2 border-white seccion">
        <h2>Ubicación</h2>
        <UbicacionM ubicacion={ubicacion} />
      </div>
    </div>
  );
};

export default Admin;
