import React from "react";
import logo from "../../assets/logos/CABANAS2.png";
import "./Loader.css";

const Loader = () => {
    const screenWidth = window.innerWidth;
    const isMobile = screenWidth < 600;
    
    if (isMobile) {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }
    
    return (
        <div className="loader">
        <img src={logo} alt="Logo" className="loader__image" />
      </div>
    );
  };

export default Loader;
