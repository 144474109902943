import React, { useState} from "react";
import "../Slider/slider.css"

const SliderComponent = ({imagesArray}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
  
    const images = imagesArray;


    const nextSlide = () => {
      setCurrentSlide((currentSlide + 1) % images.length);
    };
  
    const prevSlide = () => {
      setCurrentSlide((currentSlide + images.length - 1) % images.length);
    };
  
    return (
      <div className="cabanas">
        <div className="cabanas__slider">
          {images.map(({ id, url, alt }, index) => (
            <img
              key={id}
              src={url}
              alt={alt}
              className={`cabanas__slide ${
                index === currentSlide ? 'cabanas__slide--active' : ''
              }`}
            />
          ))}
          <button className="cabanas__prev" onClick={prevSlide}>
            &lt;
          </button>
          <button className="cabanas__next" onClick={nextSlide}>
            &gt;
          </button>
        </div>
        <div className="cabanas__dots">
          {images.map(({ id }) => (
            <span
              key={id}
              className={`cabanas__dot ${
                currentSlide === id - 1 ? 'cabanas__dot--active' : ''
              }`}
              onClick={() => setCurrentSlide(id - 1)}
            />
          ))}
        </div>
      </div>
    );
  };
    
export default SliderComponent;
