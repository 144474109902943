import React, { useState, useEffect } from "react";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../config/firebaseConfig.js";

const UbicacionM = ({ ubicacion }) => {
  const [ubicacionLocal, setUbicacionLocal] = useState(ubicacion);
  const [newDescription1, setNewDescription1] = useState("");
  const [newDescription2, setNewDescription2] = useState("");
  const [newDescription3, setNewDescription3] = useState("");

  useEffect(() => {
    const AboutUsRef = doc(db, 'ubicacion', 'complejo');
    const unsubscribe = onSnapshot(AboutUsRef, (doc) => {
      if (doc.exists()) {
        setUbicacionLocal(doc.data());
      }
    });
    return unsubscribe;
  }, []);

  const handleAddDescription1 = async (e) => {
    e.preventDefault();
    if (!newDescription1) return;
  
    // Agregar la nueva imagen al final del array 'imagenes'
    const newDescription = newDescription1;
  
    // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
    const AboutUsRef = doc(db, 'ubicacion', 'complejo');
    await updateDoc(AboutUsRef, {descripcion1: newDescription});
  
    // Actualizar el estado local de 'aboutUs' y resetear el input
    setNewDescription1(newDescription);
    setNewDescription1("");
  };
  const handleAddDescription2 = async (e) => {
    e.preventDefault();
    if (!newDescription2) return;
  
    // Agregar la nueva imagen al final del array 'imagenes'
    const newDescription = newDescription2;
  
    // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
    const AboutUsRef = doc(db, 'ubicacion', 'complejo');
    await updateDoc(AboutUsRef, {descripcion2: newDescription});
  
    // Actualizar el estado local de 'aboutUs' y resetear el input
    setNewDescription2(newDescription);
    setNewDescription2("");
  };
  const handleAddDescription3 = async (e) => {
    e.preventDefault();
    if (!newDescription3) return;
  
    // Agregar la nueva imagen al final del array 'imagenes'
    const newDescription = newDescription3;
  
    // Actualizar el documento en Firestore con el nuevo valor de 'imagenes'
    const AboutUsRef = doc(db, 'ubicacion', 'complejo');
    await updateDoc(AboutUsRef, {villa: newDescription});
  
    // Actualizar el estado local de 'aboutUs' y resetear el input
    setNewDescription3(newDescription);
    setNewDescription3("");
  };


  return(
    <div className="row">
    <form onSubmit={handleAddDescription1} className="col">
      <h2>Párrafo 1</h2>
      <div className="mb-3">
        <label htmlFor="new-image-url" className="form-label">
          Nuevo párrafo 1
        </label>
        <textarea
          className="form-control"
          id="new-image-url"
          placeholder={ubicacionLocal.descripcion1}
          value={newDescription1}
          onChange={(e) => setNewDescription1(e.target.value)}
          rows="3"
          ></textarea>
      </div>
      <button type="submit" className="btn btn-primary">
      Cambiar Párrafo
      </button>
    </form>
    <form onSubmit={handleAddDescription2} className="col">
      <h2>Párrafo 2</h2>
      <div className="mb-3">
        <label htmlFor="new-image-url" className="form-label">
          Nuevo párrafo 2
        </label>
        <textarea
          className="form-control"
          id="new-image-url"
          placeholder={ubicacionLocal.descripcion2}
          value={newDescription2}
          onChange={(e) => setNewDescription2(e.target.value)}
          rows="3"
          ></textarea>
      </div>
      <button type="submit" className="btn btn-primary">
      Cambiar Párrafo
      </button>
    </form>
    <form onSubmit={handleAddDescription3} className="col">
      <h2>Párrafo Villa</h2>
      <div className="mb-3">
        <label htmlFor="new-image-url" className="form-label">
          Nuevo párrafo Villa
        </label>
        <textarea
          className="form-control"
          id="new-image-url"
          placeholder={ubicacionLocal.villa}
          value={newDescription3}
          onChange={(e) => setNewDescription3(e.target.value)}
          rows="3"
          ></textarea>
      </div>
      <button type="submit" className="btn btn-primary">
      Cambiar Párrafo
      </button>
    </form>
  </div>
  )
}

export default UbicacionM;
