import React, { useState, useEffect } from 'react';
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../config/firebaseConfig.js"

const Authentication = ({ onVerificationSuccess}) => {
  const[code, setCode] = useState(process.env.REACT_APP_CODE);
  const[codeVerif, setCodeVerif] = useState("true");
  const [codeLocal, setCodeLocal] = useState("");

  const handleCodeChange = (event) => {
    setCodeLocal(event.target.value);
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    if(code === codeLocal){
        onVerificationSuccess();
    }else{
        setCodeVerif("false")
    }
  };

  useEffect(() => {
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Codigo:
        <input type="text" value={codeLocal} onChange={handleCodeChange} />
      </label>
      {
        codeVerif == "false"?
        <p className='text-danger'>Contraseña Incorrecta</p>
        :
        null
      }
      <button type="submit">Iniciar Sesion</button>
    </form>
  );
};

export default Authentication;
