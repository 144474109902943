import * as React from 'react';
import "./cardsLider.css"

const ImgMediaCard = ({cards}) => {
    const promociones = [];
    for (let clave in cards){
        promociones.push(cards[clave])
    }

    function replaceSpaces(inputStr) {
        // Usamos la función replace de JavaScript para reemplazar los espacios con '%20'
        let link = "https://api.whatsapp.com/send?phone=5492604608798&text=Hola!%20Estoy%20Interesado%20en%20la%20promo:%20"
        let outputStr = inputStr.replace(/ /g, '%20');
        link += outputStr
        return link;
      }


  return (
    <div className='d-flex flex-wrap contenedorCollage' style={{maxWidth: "100%"}}>
        {promociones.map((card, index)=>(
        <div className={`card col-4 mx-3 mb-4 carta ${index >= 4 ? "no-mx" : ""}`} key={card.title} style={{ minWidth: "5%" }}> 
                <img src={card.img} className="card-img-top" alt="..."/>
                <div className="card-body d-flex flex-column justify-content-center">
                    <h5 className="card-title">{card.title}</h5>
                    <p className="card-text">{card.description}</p>
                    <a target="_blank" rel="noreferrer" href={replaceSpaces(card.title)} type='button' className='card-boton btn btn-success' style={{maxWidth: "100%"}} size="small">Contacto</a>
                </div>
            </div>
        ))}
    </div>
  );
}

export default ImgMediaCard